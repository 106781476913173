/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
// 注册
import React, { useEffect, useState, useRef } from 'react'
import styles from './register.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import activityApi from '@/apis/activity'
import indexApi, { KsBuryingType } from '@/apis'
import api from '@/utils/axios'
import fingerprintjs from '@fingerprintjs/fingerprintjs'
import Skin5 from './components/skin5/skin5'
import Skin6 from './components/skin6/skin6'
import Skin7 from './components/skin7/skin7'
import Skin360 from './components/skin-360/skin-360'
import Skin8 from './components/skin8/skin8'
import Skin9 from './components/skin9/skin9'
import Skin10 from './components/skin10/skin10'
import Skin11 from './components/skin11/skin11'
import Skin12 from './components/skin12/skin12'
import Skin13 from './components/skin13/skin13'
import Skin14 from './components/skin14/skin14.'
import Skin15 from './components/skin15/skin15'
import { Storage, formatTime } from '@bihu/common-js'
import { CURRENT_SKIN_INDEX, ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import useFirstOrAfter from '@/hooks/use-first-or-after'
// import DetainmentPopup from './components/detainment-popup/detainment-popup'
import LoginPopup from './components/login-popup/login-popup'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useBuryingPoint } from '@/hooks/buryingPoint'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
interface Props {
  type?: 'getQuota', // 领取额度
}

const adChannelType = {
  register: 1,
  getQuota: 2
}

const Register: React.FC<Props> = props => {

  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const navigate = useNavigate()
  const { type } = props
  // 当前皮肤号
  const [currentSkinIndex, setCurrentSkinIndex] = useState<number>(3)

  // 是否显示弹窗
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)
  const [isSkin6, setIsSkin6] = useState<boolean>(false)
  const [isSkin7, setIsSkin7] = useState<boolean>(false)
  const [isSkin8, setIsSkin8] = useState<boolean>(false)
  const [isSkin9, setIsSkin9] = useState<boolean>(false)
  const [isSkin10, setIsSkin10] = useState<boolean>(false)
  const [isSkin11, setIsSkin11] = useState<boolean>(false)
  const [isSkin12, setIsSkin12] = useState<boolean>(false)
  const [isSkin13, setIsSkin13] = useState<boolean>(false)
  const [isSkin14, setIsSkin14] = useState<boolean>(false)
  const [isSkin15, setIsSkin15] = useState<boolean>(false)
  const [isSkin360, setIsSkin360] = useState<boolean>(false)
  const [showSkin5Page, setShowSkin5Page] = useState<boolean>(false)
  const [showPage, setShowPage] = useState<boolean>(false)
  const [codeDetail, setCodeDetail] = useState<any>()

  // 生成随机数范围
  const randomNum = (minNum:number, maxNum:number) => {
    return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
  }

  const originalPushState = window.history.pushState
  // type myPushState = typeof window.history.pushState
  // eslint-disable-next-line no-shadow
  window.history.pushState = function myPushState(...args) {
    // console.log('pushState', args, window.history.state)
    return originalPushState.apply(this, args)
  }

  useFirstOrAfter(() => {
    const skinIndex = Storage.get(CURRENT_SKIN_INDEX)
    // 有缓存的皮肤index，则+1
    if (skinIndex) {
      let temp = skinIndex + 1
      if (temp > 4) {
        temp = 1
      }

      setCurrentSkinIndex(temp)
      Storage.set(CURRENT_SKIN_INDEX, temp)
    } else { // 没有缓存皮肤index, 则随机
      let tempRandomNum = randomNum(1, 4)
      setCurrentSkinIndex(tempRandomNum)
      Storage.set(CURRENT_SKIN_INDEX, tempRandomNum)
    }
    localStorage.removeItem('productBack')
    // 判断是否分期乐渠道
    if (channelValue?.includes('fenqile')) {
      if (!Storage.get('isRefreshLoadPage') && !search.get('refresh')) {
        Storage.set('isRefreshLoadPage', true)
        window.location.href = `${document.URL}&refresh=1`
        return
      } else {
        Storage.set('isRefreshLoadPage', false)
      }

      // 监听浏览器回退事件
      window.addEventListener('popstate', back, false)
      // window.onpopstate = back
      window.history.pushState(null, 'null', document.URL)

      return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
        window.removeEventListener('popstate', back, false)
      // window.onpopstate = null
      }
    }
  }, () => {
  // eslint-disable-next-line no-console
    console.log('afterFn')
  }, [])

  // 返回
  const back = () => {
    const productBack = localStorage.getItem('productBack')
    const isShowPopupStorage = Storage.get('isShowPopup')

    try {
      if (isShowPopupStorage) {
        Storage.set('isShowPopup', false)
        setIsShowPopup(false)
        navigate(-1)

      } else {
        //  阻止浏览器回退事件
        setIsShowPopup(true)
        Storage.set('isRefreshLoadPage', false)
        // window.history.pushState(null, 'null', document.URL)
        // localStorage.setItem('productBack', formatTime(Date.now(), 'YYYY-MM-DD'))
        Storage.set('isShowPopup', true)
      }
    } catch (error) {
    // eslint-disable-next-line no-console
      // console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const [search] = useSearchParams()

  // 渠道编码
  // const [adChannelCode, setAdChannelCode] = useState('')

  const judgeLoginAfterPage = () => {
    if (channelValue?.includes('fenqile')) {
      window.location.href = `/new-fill-information?adChannelCode=${adChannelCode}` //如果访问链接是getQuota类型，就跳转表单填写页
      return
    }
    if (type === 'getQuota' && adChannelCode !== 'YxNj9v') {
      window.location.href = `/fill-information?adChannelCode=${adChannelCode}` //如果访问链接是getQuota类型，就跳转表单填写页
    } else {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ isNotFormReq: true }))}`
    }
  }

  // 有token状态下，判断下一页的跳转
  const judgeNextPage = async() => {
    // 如果是注册-下载模式，直接跳转下载页
    if (codeDetail.linkType === adChannelType.register) {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({
        isNotFormReq: true,
        isSpecialListCity: true
      }))}`
      return
    }
    // 获取表单填写状态  submitStatus >>> 0:未提交 1:已提交未授权 10:已授权
    const res: any = await indexApi.getApplyStatus()
    if (res.code === 200) {
      // 判断是否华为手机(针对个别渠道会用到，如分期乐)
      let isHuaWeiPhone = false
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('huawei') !== -1) {
        isHuaWeiPhone = true
      }
      if (res.data && res.data?.submitStatus === 1) {
      // 获取上回提交信息
        const submitResult: any = await indexApi.getFomrSubmitStatus({ adChannelCode })
        if (submitResult.productName === null) {
          window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
            isNotFormReq: true,
            rePageShowType: channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0,
            revisit: true
          }))}`)
          return
        }

        if (submitResult.thirdpartyType === 5) {
          window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(submitResult))}&adChannelCode=${adChannelCode}&isHuaWeiPhone=${isHuaWeiPhone}&applyLimit=${res.data.applyLimit}`)
        }
        // 跳转授权页
        let tempOptions = {
          adChannelCode,
          isHuaWeiPhone,
          applyLimit: res.data.applyLimit,
          workCity: res.data.workCity,
          rqResult: submitResult
        }
        if (channelValue?.includes('fenqile')) {
          window.location.replace(`/new-fill-information?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}&isHuaWeiPhone=${isHuaWeiPhone}&adChannelCode=${adChannelCode}`)
        } else {
          window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}&isHuaWeiPhone=${isHuaWeiPhone}`)
        }
      //
      } else if (res.data && res.data?.submitStatus === 10) {
        // rematchResult: 1-不需要重新匹配 2-重新匹配不到结果 3-重新匹配到结果
        const reMatchResult: any = await indexApi.getFomrSubmitStatus({ adChannelCode })
        let tempOptions:any = {}
        // 如果重现匹配并且有结果，跳至动图页
        if (reMatchResult.rematchResult === 3) {
          // 携带授权数据跳转重新匹配结果页
          tempOptions = {
            adChannelCode,
            isHuaWeiPhone,
            applyLimit: res.data.applyLimit,
            workCity: res.data.workCity,
            rqResult: reMatchResult
          }
          console.log(tempOptions)
          // eslint-disable-next-line max-depth
          window.location.replace(`/fill-information/rematch-waiting?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
          return
        }
        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0
        tempOptions.revisit = true // 标记为再访
        window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
      } else {
        // 来到这说明未提交表单
        judgeLoginAfterPage()
      }
    } else {
      setShowPage(true)
    }
  }

  const handleMainFlow = () => {
    // 获取平台来源
    const platFormSource = search.get('platformSource')
    if (platFormSource) {
      sessionStorage.setItem('FLATFORM_SOURCE', platFormSource)
    }

    const hasToken = Storage.get(TOKEN)
    // 判断是否有token(360渠道、拍拍贷渠道对token的判断单独放在各自对应的皮肤组件里)
    // const sepcialChannels = ['hBSfjw', 'ZoJERy', 'rWMspl', 'ZgvvGJ', 'pmeLeA']
    if (hasToken && !codeDetail.location) { // 有token并且不需要获取定位，接着执行下一步
      judgeNextPage()
    } else if (channelValue?.includes('hbjf')) {
      setTimeout(() => {
        setShowPage(true)
      }, 2000)
    } else {
      setShowPage(true)
    }

    if (adChannelCode) {
      // 判断如果渠道码是NRxJgP，则使用皮肤6
      if (adChannelCode === 'NRxJgP') {
        setIsSkin6(true)
      }
      // 判断如果渠道码是hBSfjw，则使用360皮肤
      if (adChannelCode === 'hBSfjw') {
        setIsSkin360(true)
      }
      // 判断如果渠道码是FnbhpG，则使用皮肤9(借呗)
      if (adChannelCode === 'FnbhpG') {
        setIsSkin9(true)
      }
      // 判断如果是借掌柜渠道，使用皮肤9
      if (channelValue?.includes('jiezhanggui')) {
        setIsSkin9(true)
      }

      if (channelValue?.includes('huanbei')) {
        setIsSkin8(true)
      }

      // if (channelValue?.includes('kuaishou')) {
      //   setIsSkin10(true)
      // }

      if (channelValue?.includes('mjy')) {
        setIsSkin11(true)
      }

      // 判断是如果是聚富渠道，则使用皮肤12
      if (channelValue?.includes('jufu')) {
        // changeIcoFavicons('jufu-favicon.ico')
        setIsSkin12(true)
      }
      // 如果是腾讯渠道，则使用皮肤14
      if (channelValue?.includes('tengxun')) {
        setIsSkin14(true)
      }
      // 享呗金服渠道，使用皮肤15
      if (channelValue?.includes('hbjf')) {
        setIsSkin15(true)
      }
      //  百度渠道，使用皮肤13
      if (channelValue?.includes('baidu')) {
        setIsSkin13(true)
      }

      setTimeout(() => {
        setShowSkin5Page(true)
      }, 10)
    }
  }

  const prevChannelValue = useRef<boolean | null>(false)

  useEffect(() => {
    if (currentSkinIndex && adChannelCode && !prevChannelValue.current) {
      prevChannelValue.current = true
      // 获取到浏览器指纹后调用上报接口
      fingerprintjs.load().then(fp => fp.get())
        .then(result => {
          const { visitorId } = result
          activityApi.reportAdChannelClick({
            adChannelCode,
            fingerprint: visitorId,
            abTestType: isSkin6 ? 6 : 5 // 固定皮肤数
          // abTestType: currentSkinIndex // 随机皮肤数
          })
        })
    }
  }, [currentSkinIndex, adChannelCode])

  useEffect(() => {
    let url = window.location.href
    const channelType = new URL(url).pathname.split('/').pop()
    localStorage.setItem('ad_channel_type', channelType || '')
    const getChannelDetail = async() => {
      const codeDetailRes:any = await activityApi.getChannelCodeDetail(adChannelCode)
      //广告渠道状态，1-正常，0-禁用
      if (codeDetailRes && codeDetailRes.channelStatus === 0) {
        window.location.href = '/failure'
        return
      }
      setCodeDetail(codeDetailRes)
    }
    if (adChannelCode) {
      getChannelDetail()
    }
  }, [adChannelCode])

  const renderSkinComponent = () => {
    switch (true) {
      case isSkin6:
        return <Skin6 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin7:
        return <Skin7 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin360:
        return <Skin360 channelDetail={codeDetail} type={type} callBack={() => judgeNextPage()} />
      case isSkin8:
        return <Skin8 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin9:
        return <Skin9 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin10:
        return <Skin10 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin11:
        return <Skin11 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin12:
        return <Skin12 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin13:
        return <Skin13 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin14:
        return <Skin14 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      case isSkin15:
        return <Skin15 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
      default:
        return showSkin5Page ? (
          <Skin360 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
        ) : null
    }
  }

  useEffect(() => {
    if (codeDetail) {
      // 执行页面主流程
      handleMainFlow()
    }
  }, [codeDetail, channelValue])

  // 特殊渠道访问埋点上报
  useEffect(() => {
    // 快手埋点（仅快手渠道）
    if (channelValue?.includes('ksBurying') && localStorage.getItem('ksCallBack')) {
      indexApi.ksBuryingPoint({
        callback: localStorage.getItem('ksCallBack')!,
        event_time: Date.now().toString(),
        event_type: KsBuryingType.Visit
      })
    }
  }, [channelValue])

  useEffect(() => {
    let uid = localStorage.getItem('user_uid')
    let token = localStorage.getItem('TOKEN')
    // 前端神策埋点 - 用户访问落地页
    sensors.track(SensorsType.visit_landing_page, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code')
    })
    // 判断，如果用户登录了并且有uid和token，可以自动登录时，就直接调用神策的登录接口进行匹配
    if (token && uid) {
      sensors.login(JSON.parse(uid))
    }
  }, [])

  return (
    <div className={styles.registerPage}>
      {
        showPage ? <>
          <div>
            {renderSkinComponent()}
            {
              isShowPopup && channelValue?.includes('fenqile') && <LoginPopup type={type} callBack={() => setIsShowPopup(false)}/>
            }
          </div>
        </> : <>
          {/* 享呗金服加载动画切换 */}
          {
            channelValue?.includes('hbjf') ? <div className={styles.hengbeiLoading} >
              <div className={styles.loadingBox}>
                <img src={require('@/assets/imgs/gif/loading.gif')} alt="" />
                <span>正在评估您的借款申请...</span>
              </div>
            </div> : <div id="root">
              <div id="loading-box">
                <div className={styles.ballClipRotateMultiple}>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          }

        </>
      }
    </div>
  )
}

export default Register
